import {autoinject} from "aurelia-framework";
import {Router} from "aurelia-router";
import { ReactWrapper } from '$pages/common/react-wrapper';
import SystemMessageManagerDetails, {ISystemMessageManagerDetailsProps} from './system-message-manager-details';

export type SystemMessageManagerUrlParams = { systemMessageId?: number };

@autoinject
export class SystemMessageManagerDetailsPage extends ReactWrapper<ISystemMessageManagerDetailsProps, SystemMessageManagerUrlParams> {
  constructor(private router: Router) {
    super(SystemMessageManagerDetails, (params) => ({
      systemMessageId: params.systemMessageId,
      onCancel: () => { this.router.navigateToRoute("systemmessagemanagerlist") },
      onSave: () => { this.router.navigateToRoute("systemmessagemanagerlist") },
    }));    
  }
}
