
// Aurelia route names, and URI templates for navigation in react

export const list_route_name = "systemmessagemanagerlist";

export const edit_route_name = "systemmessagemanagerdetailsedit";

export const edit_uri_template: string = "system-message-manager/edit/{0}";

export const create_route_name = "systemmessagemanagerdetailscreate";
