import {default as React, FC, useEffect} from 'react';
import {useCaseInsensitiveTranslation} from "$lib/hooks/case-insensitive-translation";
import {SubmitHandler, UseFormSetValue, useForm} from "react-hook-form";
import { mutate, useQuery } from '$lib/hooks/fetch-utillities';
import Card from "$components/cards/card/card.react";
import Skeleton from "$components/placeholders/skeleton/skeleton";
import ErrorText from "$components/texts/error-text/error-text.react";
import CardContent from '$components/cards/card-content/card-content.react';
import FormElement from "$components/forms/form-element/form-element.react";
import DatePicker from "$components/date-picker/date-picker.react";
import SystemMessageManagerDetailsTextEditor
  from "$pages/system-message-manager/modules/system-message-manager-details-text-editor/system-message-manager-details-text-editor";
import './system-message-manager-details.css';
import {DateTime} from "luxon";
import {
  SystemMessageUpsertVariables,
  SystemMessageInput,
  
} from "$typings/graphql";
import {SystemMessageEditableDocument, SystemMessageUpsertDocument, SystemMessageEditable, SystemMessageTranslateDocument, SystemMessageTranslateMutation} from "$typings/graphql-codegen";
import { ensureNumber } from '$lib/numberHelpers';
import { Button } from '@mui/material';
import Icon from '$components/icons/icon/icon.react';

export interface ISystemMessageManagerDetailsProps {
  systemMessageId?: number,
  onCancel: () => void,
  onSave: () => void
}

export interface IMessageLocalized {
  systemMessageTextId?: number;
  languageCode: string;
  title: string;
  message: string
}

export interface ISystemMessageManagerFormValues {
  startDate?: Date;
  endDate?: Date;

  en: IMessageLocalized,
  de: IMessageLocalized,
  fr: IMessageLocalized,
  es: IMessageLocalized,
  it: IMessageLocalized,
  pl: IMessageLocalized,
  nl: IMessageLocalized,
  sv: IMessageLocalized,
  nb: IMessageLocalized,
  hu: IMessageLocalized,
}

// These type definitions are also used in  the s-m-m-details-text-editor.tsx component. They are used for
// setting values in react hook form, when generating a form key based on the 2-letter language code alone (like "en" or "nb"):
//
export type LanguageType = "en" | "de" | "fr" | "es" | "it" | "pl" | "nl" | "sv" | "nb" | "hu";
export type TitleType =  "en.title" | "de.title" | "fr.title" | "es.title" | "it.title" | "pl.title" | "nl.title" | "sv.title" | "nb.title" | "hu.title";
export type DetailsType =  "en.message" | "de.message" | "fr.message" | "es.message" | "it.message" | "pl.message" | "nl.message" | "sv.message" | "nb.message" | "hu.message";

const Languages = ["en", "de", "fr", "es", "it", "pl", "nl", "sv", "nb", "hu"]
/**
 * forceUTC takes a JS Date in the local timezone, and returns an UTC ISO string that has the same date and time values, but in the UTC timezone instead. 
 * So "2023-02-01 12:31" in local time zone results in an UTC ISO string like "2023-02-01T12:31Z".
 * @param dateString date & time from UI (DateTime picker)
 */
const forceUTC = (date: Date | undefined | null): string | undefined => {
  if (date === undefined || date === null) return undefined;
  const localNow = DateTime.fromJSDate(new Date());
  const utcLuxonDate = DateTime.fromJSDate(date, { zone: "UTC" }).plus({ minutes: localNow.offset })
  return utcLuxonDate.toISO();
}

/**
 * forceLocalTimezone takes an UTC ISO date-time string, and returns a JS Date with the same date and time values, but in the local timezone instead of UTC.
 * Same principle as "forceUTC".
 * @param dateTimeStr 
 * @returns 
 */
const forceLocalTimezone = (dateTimeStr: string | undefined | null): Date | undefined => {
  if (dateTimeStr === undefined || dateTimeStr === null) return undefined;
  const localNow = DateTime.fromJSDate(new Date());

  const result = (DateTime
    .fromISO(dateTimeStr)
    .setZone('UTC')
    .minus({ minutes: localNow.offset})
    .toJSDate());

  return result;
}

const currentUTCIsoString = (): string => DateTime.fromJSDate(new Date()).setZone('UTC').toISO();

const createDTOForMutation = (values: ISystemMessageManagerFormValues, systemMessageId?: number):SystemMessageInput  => ({
    startUtc: (values.startDate === undefined ?  currentUTCIsoString() : forceUTC(values.startDate) as string),
    endUtc: forceUTC(values.endDate),
    systemMessageId,
    systemMessageTexts: [
    {
      ...values.en,
      languageCode: "en"
    },
    {
      ...values.de,
      languageCode: "de"
    },
    {
      ...values.fr,
      languageCode: "fr"
    },
    {
      ...values.es,
      languageCode: "es"
    },    
    {
      ...values.it,
      languageCode: "it"
    },
    {
      ...values.pl,
      languageCode: "pl"
    },
    {
      ...values.nl,
      languageCode: "nl"
    },
    {
      ...values.sv,
      languageCode: "sv"
    },
    {
      ...values.nb,
      languageCode: "nb"
    },
    {
      ...values.hu,
      languageCode: "hu"
    }
  ]
});

const ensureString = (item: string | null | undefined): string => {
  return !!item ? item : '';
};

const initializeFormValues = (setValue: UseFormSetValue<ISystemMessageManagerFormValues>, dto: SystemMessageEditable): void => {
  setValue('startDate', forceLocalTimezone(dto.startUtc));
  setValue('endDate', forceLocalTimezone(dto.endUtc));

  for (const message of dto.systemMessageTexts) {
    if (!message || !message.languageCode) continue;
    
    const languageType = message.languageCode as LanguageType;
    const titleKey = `${languageType}.title` as TitleType;
    const messageKey = `${languageType}.message` as DetailsType;

    setValue(titleKey, ensureString(message.title));
    setValue(messageKey, ensureString(message.message));
  }
}

const SystemMessageManagerDetails : FC<ISystemMessageManagerDetailsProps> = ({
     systemMessageId,
     onCancel, 
     onSave
  }) => {
  const [t] = useCaseInsensitiveTranslation();

  const sanitizedSystemMessageId = systemMessageId ? ensureNumber(systemMessageId) : 0;
  
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { isSubmitting, errors },
    setError,
    clearErrors
  } = useForm<ISystemMessageManagerFormValues>();
  
  const { data, loading, error } = useQuery(SystemMessageEditableDocument, 
    { systemMessageId: sanitizedSystemMessageId });
 
  useEffect(() => {
    if (!data || !data.systemMessages || !data.systemMessages.editable) return;
    initializeFormValues(setValue, data.systemMessages.editable);
  }, [data]);
  
  const performSubmit = async (values: ISystemMessageManagerFormValues) => {
    // Convert form values to DTO for sending to API layer:
    const variables: SystemMessageUpsertVariables = {
      input: createDTOForMutation(values, sanitizedSystemMessageId)
    };
    await mutate(SystemMessageUpsertDocument, variables);
    onSave();
  }

  const onStopShowingCurrentMessage = async () => {
    // Set end date to the current ISO time minus one minute. This code ensures the message will not be showed anymore.
    const now = DateTime.fromJSDate(new Date());
    const minutesCorrection = 1 + now.offset;  // this ensures the date is correct in performSubmit()
    const cutoffDate = now.minus({ minutes: minutesCorrection }).toJSDate();
    setValue("endDate", cutoffDate);
    await performSubmit(getValues());
  }
  
  const onFormSubmit: SubmitHandler<ISystemMessageManagerFormValues> = async values => {
    await performSubmit(values);
  };
  
  const { startDate, endDate, en } = getValues();
  
  const setTranslatedMessageValues = (result: SystemMessageTranslateMutation["translate"]) => { 
    result.translations.forEach(res => {
      const messageAlreadyHasValue = !!getValues(`${res.to}.message` as DetailsType)
      
      if(messageAlreadyHasValue) return
      return setValue(`${res.to}.message` as DetailsType, res.text);
    })
  }

  const setTranslatedTitleValues = (result: SystemMessageTranslateMutation["translate"]) => { 
    result.translations.forEach(res => {
      const titleAlreadyHasValue = !!getValues(`${res.to}.title` as TitleType)
      if(titleAlreadyHasValue) return
      return setValue(`${res.to}.title` as TitleType, res.text);
    })
  }

  const translateFromEnglish = () => {
    if(!en.message) return
    mutate(SystemMessageTranslateDocument, {textToTranslate: en.message, translateToLanguages: Languages }).then(res => setTranslatedMessageValues(res.translate))
    mutate(SystemMessageTranslateDocument, {textToTranslate: en.title, translateToLanguages: Languages }).then(res => setTranslatedTitleValues(res.translate))
  }

  return (
    <>
      <form onSubmit={handleSubmit(onFormSubmit)} className="system-message-manager">

        {(loading || error) && (
          <Card className="smm-card">
            <CardContent className="relative">
              {errors && <ErrorText>{JSON.stringify(errors)}</ErrorText>}
              <Skeleton rowCount={6} />
            </CardContent>
          </Card>
        )}
        {!loading && !error && (
          <Card className="smm-card">
            <CardContent>
              <h2>{t('UI_SystemMessages_Screen_Title')}</h2>
              <div className="grouping">
              <h3>{t('UI_SystemMessages_Required_English_Text')}</h3>
  
              <div>
                <SystemMessageManagerDetailsTextEditor
                  register={register}
                  watch={watch}
                  setValue={setValue}
                  errors={errors}
                  setError={setError}
                  clearErrors={clearErrors}
                  languageCode="en"
                  required={true}
                />
              </div>
  
              <div className="flex controls-wrapper">
                <div className="flex flex_1">
                  <FormElement label={t('UI_SystemMessages_FromTimeUTC')} direction="column">
                    <DatePicker
                      showTimeSelector={true}
                      onChanged={date => setValue("startDate", date)}
                      selectedDate={startDate}>

                    </DatePicker>
                  </FormElement>
                  <FormElement label={t('UI_SystemMessages_ToTimeUTC')} direction="column" className="mar_ls">
                    <DatePicker
                      showTimeSelector={true}
                      onChanged={date => setValue("endDate", date)}
                      selectedDate={endDate}>
                    </DatePicker>
                  </FormElement>
                </div>
                <div className="smm-buttons flex flex_1 jcflexend ai-end gap_s">
                  <Button variant="outlined" onClick={onStopShowingCurrentMessage}>
                    {t('UI_SystemMessages_StopShowing')}
                  </Button>
                    <Button variant="outlined" onClick={onCancel}>
                    {t('UI_Common_Cancel')}
                  </Button>
                    <Button type="submit" startIcon={isSubmitting && <Icon name="fa-spinner fa-pulse" />} disabled={isSubmitting} variant="contained">
                    {t('UI_Common_Save')}
                  </Button>
                </div>
              </div>
              </div>
              <div className='flex jcflexend mar_bm'>
                <Button variant='contained' onClick={translateFromEnglish}>{t("ui_systemmessages_translate_all")}</Button>
              </div>
              <div className="grouping">
              <h3>{t('UI_Common_Optional')}</h3>
  
                <div>
                  <h4>Deutsch</h4>
                  
                <SystemMessageManagerDetailsTextEditor
                  register={register}
                  watch={watch}
                  setValue={setValue}
                  languageCode="de"
                  required={false}
                />
              </div>
  
                <div>
                  <h4>Français</h4>
                  
                <SystemMessageManagerDetailsTextEditor
                  register={register}
                  watch={watch}
                  setValue={setValue}
                  languageCode="fr"
                  required={false}
                />
              </div>

              <div>
                <h4>Español</h4>
  
                <SystemMessageManagerDetailsTextEditor
                  register={register}
                  watch={watch}
                  setValue={setValue}
                  languageCode="es"
                  required={false}
                />
              </div>             
  
              <div>
                <h4>Italiano</h4>
  
                <SystemMessageManagerDetailsTextEditor
                  register={register}
                  watch={watch}
                  setValue={setValue}
                  languageCode="it"
                  required={false}
                />
              </div>

              <div>
                <h4>Polski</h4>
  
                <SystemMessageManagerDetailsTextEditor
                  register={register}
                  watch={watch}
                  setValue={setValue}
                  languageCode="pl"
                  required={false}
                />
              </div>

              <div>
                <h4>Nederlands</h4>
  
                <SystemMessageManagerDetailsTextEditor
                  register={register}
                  watch={watch}
                  setValue={setValue}
                  languageCode="nl"
                  required={false}
                />
              </div>

              <div>
                <h4>Svenska</h4>
  
                <SystemMessageManagerDetailsTextEditor
                  register={register}
                  watch={watch}
                  setValue={setValue}
                  languageCode="sv"
                  required={false}
                />
              </div>

              <div>
                <h4>Norsk Bokmål</h4>
  
                <SystemMessageManagerDetailsTextEditor
                  register={register}
                  watch={watch}
                  setValue={setValue}
                  languageCode="nb"
                  required={false}
                />
              </div>

              <div>
                <h4>Magyar</h4>
  
                <SystemMessageManagerDetailsTextEditor
                  register={register}
                  watch={watch}
                  setValue={setValue}
                  languageCode="hu"
                  required={false}
                />
              </div>
            </div>
            </CardContent>
          </Card>
        )}
      </form>
    </>);
}

export default SystemMessageManagerDetails;
