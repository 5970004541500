import {default as React, FC, useMemo, useState} from "react";
import Grid, {IReactGridColumn} from '$components/grid/react-grid/grid.react';
import {useCaseInsensitiveTranslation} from "$lib/hooks/case-insensitive-translation";
import { useGridData } from '$components/grid/react-grid/gridhelper';
import {
  SystemMessageForListing,
  SystemMessageManagerListDocument,
  SortDirection,
} from '$typings/graphql-codegen';
import GridCell from "$components/grid/react-grid/cell/grid-cell";
import Button from "$components/buttons/button.react";
import Card from "$components/cards/card/card.react";
import CardContent from '$components/cards/card-content/card-content.react';
import { pureFormatDate } from "$lib/dateHelpers";
import { orderByPredicate } from "$lib/sorting";
import { makeSortedData } from "$pages/reports/makeSortedData";

export interface ISystemMessageManagerListProps {
  onCreate: () => void,
  onEdit: (systemMessageId: number) => void
}

const getColumns = (): IReactGridColumn<SystemMessageForListing>[] => {
  return [
    {
      columnTitle: 'UI_SystemMessages_Active',
      columnKey: 'active',
      initialWidth: "8rem",
      render: (item, width) => (
        <GridCell width={width} style={item.active ? { fontWeight: 'bold' } : {}}>{item.active ? 'Active' : '' }</GridCell>
      )
    },
    {
      columnTitle: 'UI_SystemMessages_FromTimeUTC',
      columnKey: 'showFrom',
      initialWidth: "16rem",
      render: (item) => (
        <GridCell>{!!item.showFrom ? pureFormatDate(item.showFrom, true, '.') : '' }</GridCell>
      )
    },
    {
      columnTitle: 'UI_SystemMessages_ToTimeUTC',
      columnKey: 'showTo',
      initialWidth: "16rem",
      render: (item) => (
        <GridCell>{!!item.showTo ? pureFormatDate(item.showTo, true, '.') : '' }</GridCell>
      )
    },
    {
      columnTitle: 'UI_SystemMessages_Title',
      columnKey: 'title',
      initialWidth: "20rem",
      render: (item) => (
        <GridCell>{item.title}</GridCell>
      )
    }
  ];
}

type SystemMessageManagerListSortColumnKeys = "active" | "showFrom" | "showTo" | "title";

type SystemMessageManagerListSortValues = {
  sortColumnKey: SystemMessageManagerListSortColumnKeys,
  sortDirection: SortDirection;
}

type SortOutputType = Array<SystemMessageForListing>;

const sortSystemMessageList = (rows: Array<SystemMessageForListing | undefined | null>, sortDirection: SortDirection, sortColumnKey: SystemMessageManagerListSortColumnKeys): SortOutputType | undefined => {
  if (!rows) return rows;
  const saneRows: SortOutputType = rows.filter(row => row !== undefined && row !== null) as SortOutputType;
  const direction = sortDirection === SortDirection.Asc ? 'asc' : 'desc';

  if (sortColumnKey == 'active') {
    return orderByPredicate(saneRows, item => ((!!item && !!item.active) ? 1 : -1) , direction, false);
  }
  else if (sortColumnKey == 'showFrom' || sortColumnKey == 'showTo' || sortColumnKey == 'title') {
    // showFrom and showTo are both sortable ISO strings, and title is just a string:
    return makeSortedData(saneRows, direction, sortColumnKey) as (SortOutputType | undefined);
  }
  return saneRows;
}


const SystemMessageManagerList : FC<ISystemMessageManagerListProps> = ({
  onCreate,
  onEdit
  }) => {
  const [t] = useCaseInsensitiveTranslation();

  const data = useGridData(
    SystemMessageManagerListDocument,
    {},
    t => (!!t.systemMessages ? t.systemMessages.listing.length : 0),
    t => (!!t.systemMessages ? t.systemMessages.listing : [])
  );
  
  const [sortValues, setSortValues] = useState<SystemMessageManagerListSortValues>({
    sortColumnKey: "active",
    sortDirection: SortDirection.Desc
  });

  const getColumnsMemo = useMemo(getColumns, []);

  const orderedData = {
    ...data,
    data: data.data ? sortSystemMessageList(data.data, sortValues.sortDirection, sortValues.sortColumnKey) : undefined
  };
    
  return (
    <>
      <Card className="mar_lm mar_rm">
        <CardContent>
          <h2>{t('UI_SystemMessages_Listing')}</h2>
    
          <div className="flex row-reverse">
            <Button type="submit" variant="primary" className="mar_bm" onClick={onCreate}>
              <span>{t('UI_Common_Create')}</span>
            </Button>
          </div>
          
          <div className="flex" style={{ height: 400 }}>
            <Grid
              className="clickable"
              name="report-schedule-system-grid"
              items={orderedData}
              loading={data.isRevalidating && !data.data}
              columns={getColumnsMemo}
              sortedColumnKey={sortValues.sortColumnKey}
              sortedDirection={sortValues?.sortDirection}
              sortChanged={(sortByColumn, sortDirection) =>
                setSortValues({
                  sortColumnKey: sortByColumn,
                  sortDirection: sortDirection
                })
              }
              onRowClick={(row) => () => onEdit(row ? row["systemMessageId"] : 0)}
            />
          </div>
        </CardContent>
      </Card>
    </>);
}
export default SystemMessageManagerList;
