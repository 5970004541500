import SystemMessageManagerList from './system-message-manager-list';
import { ReactWrapper } from '$pages/common/react-wrapper';
import { autoinject } from "aurelia-framework";
import { Router } from "aurelia-router";
import { create_route_name, edit_route_name } from '$pages/system-message-manager/system-message-manager-definitions';

@autoinject
export class SystemMessageManagerListPage extends ReactWrapper {
  constructor(private router: Router) {
    super(SystemMessageManagerList, () => ({
      onCreate: () => { this.router.navigateToRoute(create_route_name) },
      onEdit: (systemMessageId: number) => { this.router.navigateToRoute(edit_route_name, { systemMessageId }) },
    }))    
  }
}
