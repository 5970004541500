import { autoinject, PLATFORM } from 'aurelia-framework';
import { RouterConfiguration } from 'aurelia-router';

@autoinject()
export class SystemMessageManagerPage {
  configureRouter(config: RouterConfiguration) {
    config.map([
      {
        route: '',
        name: 'systemmessagemanagerlist',
        moduleId: PLATFORM.moduleName(
          './modules/system-message-manager-list/system-message-manager-list-page'
        ),
        title: 'UI_SystemMessages_Screen_Title'
      },
      { route: 'list', redirect: '' },
      {
        route: 'edit/:systemMessageId?',
        name: 'systemmessagemanagerdetailsedit',
        moduleId: PLATFORM.moduleName(
          './modules/system-message-manager-details/system-message-manager-details-page'
        ),
        title: 'UI_SystemMessages_Screen_Title'
      },
      {
        route: 'create',
        name: 'systemmessagemanagerdetailscreate',
        moduleId: PLATFORM.moduleName(
          './modules/system-message-manager-details/system-message-manager-details-page'
        ),
        title: 'UI_SystemMessages_Screen_Title'
      },      
    ]);
  }
}

