import {default as React, FC} from 'react';
import ReactQuill from 'react-quill';
import { DeltaStatic, Sources } from 'quill';
import 'react-quill/dist/quill.snow.css'; // Import the Quill styles
import TextField from '$components/textbox/text-field.react';
import FormElement from '$components/forms/form-element/form-element.react';
import {
  DetailsType,
  ISystemMessageManagerFormValues, LanguageType, TitleType,
} from "$pages/system-message-manager/modules/system-message-manager-details/system-message-manager-details";
import {
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
  UseFormWatch
} from "react-hook-form/dist/types/form";
import {useCaseInsensitiveTranslation} from "$lib/hooks/case-insensitive-translation";
import { FieldErrors} from "react-hook-form";
//import ErrorText from "$components/texts/error-text/error-text.react";
//import {validateQuillEditorHTMLOutputIsNotEmpty} from "$lib/validationHelpers";
import './system-message-manager-details-text-editor.css';
import { Button } from '@mui/material';

interface ISystemMessageManagerDetailsTextEditorProps {
  // these props come from react-hook-form:
  register: UseFormRegister<ISystemMessageManagerFormValues>,
  setValue: UseFormSetValue<ISystemMessageManagerFormValues>,
  watch: UseFormWatch<ISystemMessageManagerFormValues>,
  errors?: FieldErrors<ISystemMessageManagerFormValues>,
  setError?: UseFormSetError<ISystemMessageManagerFormValues>,
  clearErrors?: UseFormClearErrors<ISystemMessageManagerFormValues>,
  // regular props:
  languageCode: string,
  required: boolean
}


/**
 * Reusable component
 */
const SystemMessageManagerDetailsTextEditor : FC<ISystemMessageManagerDetailsTextEditorProps> = 
  ({
     register,
     setValue,
     watch,
     errors,
    //  setError,
    //  clearErrors,
     languageCode,
     required
  }) => {
  const [t] = useCaseInsensitiveTranslation();

  // make a couple of unique keys to be able to set data in the form
  const languageCodeKey: LanguageType = languageCode as LanguageType; 
  const titleUniqueKey: TitleType = `${languageCode}.title` as TitleType;
  const descriptionUniqueKey: DetailsType = `${languageCode}.message` as DetailsType;

  // read/write contents from quill from/into the form:
  const richTextEditorContent = watch(descriptionUniqueKey);
  
  // const validateEditorContent = (content: string): boolean => {
  //   if (required) {
  //     if (!validateQuillEditorHTMLOutputIsNotEmpty(content)) {
  //       setError && setError(descriptionUniqueKey, {message: t("UI_Common_Required"), type: "required"});
  //       return false;
  //     }
  //     clearErrors && clearErrors(descriptionUniqueKey);  
  //   }
  //   return true;
  // }
  
    const handleRichTextEditorChange = (content: string, _delta: DeltaStatic, _source: Sources, editor: ReactQuill.UnprivilegedEditor) => {
      if (editor.getText().trim() === "") return setValue(descriptionUniqueKey, "");
      //validateEditorContent(content);
      setValue(descriptionUniqueKey, content);
  };

  //const editorErrorMessage = errors && errors[languageCodeKey];
  
    const clearFields = () => { 
      setValue(titleUniqueKey, "")
      setValue(descriptionUniqueKey, "")
    }
    
  return (
    <>
      <FormElement label={t('UI_SystemMessages_Title')} direction="column">
        <TextField
          {...register(titleUniqueKey,
            (required ? {
              required: t('UI_Common_Fields_RequiredField') as string,
            } : {})
          )}
          error={errors && errors[languageCodeKey]?.title?.message}
        />
      </FormElement>
      <FormElement 
          label={t('UI_SystemMessages_Message')} 
          direction="column" 
          //</>className={!!editorErrorMessage ? "system-message-manager-details-text-editor-error" : ""}
          >
        <ReactQuill
          theme="snow" // Specify the theme for the editor (other options: "bubble", "core")
          value={richTextEditorContent} // The editor content
          onChange={handleRichTextEditorChange} // Handle changes to the editor content
        />
        {/*<ErrorText>{editorErrorMessage !== undefined && t('UI_Common_Fields_RequiredField')}</ErrorText>*/}
        <div className='flex jcflexend mar-ts'>
        <Button variant='outlined' onClick={clearFields}>Clear fields</Button>
        </div>
      </FormElement>
    </>
  );
}

export default SystemMessageManagerDetailsTextEditor;
